.root {
  padding: 8px 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  font-size: 14px;
}

.rightMenu {
  margin: 0;
  padding: 0;
  list-style-type: none;

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
  flex-wrap: wrap;
}

.rightMenuItem {
  position: relative;
}

.rightMenuItem:not(:last-child)::after {
  content: "";
  width: 1px;
  height: 18px;

  position: absolute;
  right: -10px;
  top: 2px;

  background-color: #fff;
}

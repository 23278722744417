.popup__header {
  padding: 8px 16px;
  font-weight: bolder;
  font-size: 1.15rem !important;
}

.popup__body {
  padding: 8px 16px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 12px;
}

.popup__line {
  height: 1px;
  background-color: #6a6a6a;
  margin: 4px 16px;
}

.popup__device-status {
  display: flex;
  align-items: center;
  column-gap: 8px;

  font-size: 0.75rem !important;
}

.dashboard-map {
  overflow: hidden;
  border-radius: 5px;

  &__content {
    position: relative;
    max-width: 100%;
    margin-top: 50px;
  }
}

.popup__text {
  font-weight: 500;
  font-size: .8rem !important;
  letter-spacing: 0.05em;

  sub,
  sup {
    font-size: .75rem !important;
  }
}

.root :global(.dropdown-menu) {
  padding: 0;
  overflow: hidden;
  z-index: 6000;

  border-radius: 0.25rem;
  border: 1px solid var(--t-panel-border);
  background-color: var(--t-panel-bg);
}

.root :global(.dropdown-header) {
  padding: 8px 20px;

  color: var(--t-common-text) !important;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;

  background-color: var(--t-special-item);
}

.root :global(.dropdown-item) {
  padding: 8px 20px;
  color: var(--t-common-text) !important;
  font-size: 14px;
  line-height: 1.5;
  cursor: pointer;
  transition: all .2s ease;
}

.root :global(.dropdown-item:hover) {
  background-color: var(--t-list-item-hover);
}

.root :global(.dropdown-item.active) {
  background-color: var(--t-accent-element);
  color: var(--t-accent-text-contrast-color) !important;
}

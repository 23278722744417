// Here you can add other styles

@function theme-color-dt($key: "primary") {
  @return map-get($theme-colors__dt, $key);
}

@function theme-color($key: "primary") {
  @return map-get($theme-colors, $key);
}

html {
  scrollbar-width: none;
}

body {
  background-color: var(--app-background)!important;
  -ms-overflow-style: none;
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
}

body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.authorization-layout {
  min-height: 100vh;

  @media (max-width: 768px) {
    height: var(--app-height) !important;
    min-height: var(--app-height) !important;
    overflow-y: scroll;
  }
}

@media (max-width: 500px) {
  .filepond--file {
    display: flex;
    flex-direction: column;
  }
  .filepond--file-info {
    max-width: calc(100% - 38px);
    font-size: 1rem !important;
  }
  .filepond--file-status {
    max-width: 100%;
    width: 100%;
    margin: 0 !important;
    transform: translate3d(0, 0px, 0px) !important;
  }
  .filepond--file-status * {
    white-space: normal !important;
  }
  .filepond--file-status .filepond--file-status-sub,
  .filepond--file-info .filepond--file-info-sub {
    font-size: 1rem !important;
  }
}

@media (max-width: 375px) {
  .filepond--file-status .filepond--file-status-sub,
  .filepond--file-info .filepond--file-info-sub {
    font-size: 0.75rem !important;
  }
}

pre {
  white-space: normal;
}

.simple-flex {
  display: flex;
  align-items: center;
}

.c-app {
  flex-direction: row !important;
  align-items: center !important;
  font-family: "Gilroy", sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 1.1rem;
}

.sub,
.sup {
  font-size: .75rem !important;
}

@media (min-width: 1400px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1140px;
  }
}

.auth-container {
  .col {
    .card-body {
      min-width: 290px;
    }
  }
}

@media screen and (min-width: 576px) {
  .auth-container {
    .col {
      .card-body {
        min-width: 1px;
        width: 100%;
      }
    }
  }

  .media-flex {
    justify-content: flex-end;
  }
}

@media screen and (max-width: 360px) {
  .auth-container {
    .col {
      .card-body {
        min-width: 1px;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .transformed-flex {
    flex-direction: column;
    justify-content: center !important;
  }

  .media-flex {
    justify-content: center;
    align-items: center;
  }

  .small-screen__button {
    margin: 10px 6px 10px 0;
    min-width: 220px;
  }
}

.valid,
.invalid {
  font-style: italic;
  font-size: 80% !important;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  text-align: left;

  & ul {
    li {
      font-size: 80% !important;
    }
  }
}

.invalid {
  color: #eb593a;
}

.valid {
  color: #8d90fb;
}

.filepond--drop-label {
  min-height: 76px !important;

  * {
    font-size: 1rem !important;
  }
}

.error-page-layout {
  width: 100vw;
  min-height: 100vh;
  background-color: #ebedef;
  color: #3c4b64;
  display: flex;
  align-items: center;
  justify-content: center;

  .aligner {
    max-width: 1140px;
    width: 1140px;
    padding: 0 15px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .grouper {
      max-width: 50%;
      display: flex;
      justify-content: center;

      @media (max-width: 1200px) {
        max-width: 80%;
        flex-direction: column;
        text-align: center;
      }

      div {
        margin-right: 2rem;

        @media (max-width: 1200px) {
          margin-right: 0;
        }

        h4 {
          font-size: 1.3125rem !important;
          font-weight: 600 !important;
          padding-top: 1rem !important;
        }
      }
    }
  }
}

// Code Mirror styles

.cm-editor * {
  font-size: 1rem !important;
}

.collapse-arrow {
  width: 12px;
  height: 9px;
  display: inline-block;
  cursor: pointer;
  background: center no-repeat url("../assets/icons/collapseArrow.svg");
}

.collapse-arrow_white-theme {
  width: 12px;
  height: 9px;
  display: inline-block;
  cursor: pointer;
  background: center no-repeat url("../assets/icons/collapseArrowBlack.svg");
}

@media (min-width: 768px) {
  .table-responsive {
    width: 100%;
    overflow-x: unset;
  }
}

@media (max-width: 768px) {
  .mobile-table {
    padding: 12px;
  }

  .mobile-table {
    .form-group {
      display: grid;
      grid-template-columns: min-content min-content;
      justify-items: start;
      gap: 10px;
    }
  }
}

@media (max-width: 420px) {
  .mobile-table {
    td {
      max-width: 130px;
    }
  }

  .mobile-table {
    .form-group {
      .form-control {
        min-width: 50px !important;
      }
    }
  }
}

@media (min-width: 576px) and (max-width: 992px) {
  .col-sm-8 {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

// Chart tooltips styles

#chartjs-tooltip {
  opacity: 1;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 3px;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  pointer-events: none;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  width: auto;
  min-width: 160px;
  max-width: 300px;

  @media (max-width: 767px) {
    max-width: 250px;
  }

  table,
  thead,
  th,
  td,
  b, span {
    border-width: 0 !important;
    color: #fff!important;
  }
}

.chartjs-tooltip-key {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

.table-icon {
  width: 1.3rem !important;
  height: 1.3rem !important;
}

.hidden-overflow {
  overflow-y: hidden !important;
}

.input--hide-arrows {
  input {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type=number] {
      -moz-appearance: textfield;
    }
  }
}

// Table search styles

@media (max-width: 580px) {
  .col-sm-auto {
    width: 100% !important;
  }
  .col-auto {
    width: 100% !important;
  }
}


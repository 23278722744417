.logoWrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  display: block;
  width: 190px;
  height: 160px;
  fill: var(--t-common-text);
}

.headerDropdown {
  position: absolute !important;
  top: 8px;
  right: 8px;
}

.cardBody {
  height: 100%;
}

@media (max-width: 479px) {
  .logo {
    width: 140px;
    height: 120px;
  }
}

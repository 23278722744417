.root {
  position: relative;
}

.input {
  display: block;
  width: 100%;
  height: 40px;
  padding: 0 8px;

  font-size: 1.1rem;
  color: var(--t-input-text);

  background-color: var(--t-input-bg);
  border: 1px solid var(--t-input-border);

  transition: all .2s ease;
}

.input:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 181, 181, .25);
}

.input:disabled {
  color: var(--t-common-text-disabled) !important;
}

.input:disabled + .iconWrapper {
  color: var(--t-common-text-disabled) !important;
}

input[type=number]::-webkit-inner-spin-button  {
  -webkit-appearance: none;
}

.numberInput {
  position: absolute;
  height: 40px;
  top: 0;
  right: 0;
  font-size: 8px;
  line-height: 1;
  padding: 0 !important;
  border: none !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.numberInput div {
  border: none !important;
  cursor: pointer;
  opacity: .7;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 0.25rem
}

.numberInput div:hover {
  opacity: 1;
}

.numberInputUp {
  padding: 8px 8px 4px !important;
}

.numberInputDown {
  padding: 4px 8px 8px !important;
}

.iconWrapper {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);

  display: block;
  width: 16px;
  height: 16px;
}

.iconWrapper svg {
  display: block;
  width: 100%;
  height: 100%;
}

.withIcon .input {
  padding-right: 32px;
}

.rootRounded .input {
  border-radius: 4px;
}

.rootContrast .input {
  color: var(--t-input-text-contrast);
  background-color: var(--t-input-bg-contrast);
  border: 1px solid var(--t-input-border-contrast);
}

/* status modifier  */
.status--invalid .input {
  border-color: var(--t-status-invalid);
}
.status--invalid .input:focus {
  box-shadow: 0 0 0 0.2rem var(--t-status-invalid-transparent);
}
.status--invalid .iconWrapper {
  color: var(--t-status-invalid);
}

.status--valid .input {
  border-color: var(--t-status-valid);
}
.status--valid .input:focus {
  box-shadow: 0 0 0 0.2rem var(--t-status-valid-transparent);
}
.status--valid .iconWrapper {
  color: var(--t-status-valid);
}

.root {
  display: flex;
  justify-content: center;
  align-items: center;
}

.position--top {
  align-self: baseline;
  margin: 5px 0;
}

.position--center {
  width: 100%;
  height: 100vh;
}

.planBannerContainer {
  position: relative;
  margin-right: 32px;
}

.planBanner {
  cursor: pointer;
}

.popupContainer {
  z-index: 100;
}

.title {
    font-weight: bold
}

.price {
  font-size: 18px;
  font-weight: bold
}

.noBorder {
  border: none !important;
}

@media (max-width: 767px) {
  .enterpriseBenefits {
    display: none !important;
  }
}

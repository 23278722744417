.root {
  position: sticky;
  top: 0;
  z-index: 1029;
  background-color: var(--t-plain-bg);
  box-shadow: var(--t-header-shadow);
}

.topLine {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  padding: 0 32px;
  border-bottom: 1px solid var(--t-panel-border);
}

.burger {
  cursor: pointer;
}

.brand {
  display: flex;
  align-items: center;
  height: 46px;
  width: 118px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.rightMenu {
  display: flex;
  align-items: center;
}

.notifications {
  position: relative;
  margin-right: 24px;
  cursor: pointer;
}

.notificationsIcon {
  display: block;
}

.subheader {
  position: relative;
  width: 100%;
  min-height: 48px;
  padding: 0 32px;
}

@media screen and (max-width: 991px) {
  .root {
    position: static;
  }
}

@media screen and (max-width: 575px) {
  .topLine {
    padding: 0 16px;
  }

  .subheader {
    padding: 0 16px;
  }
}

.content {
  z-index: 1000;
  width: 250px;
  border-radius: 4px;
  overflow: hidden;
  font-size: 14px !important;
  font-weight: normal;
  color: var(--t-common-text);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background-color: var(--t-panel-bg);
}

.title {
  background-color: var(--t-card-accent-bg);
  padding: 0.5rem;
  font-weight: bold;
}

.description {
  padding: 0.5rem;
}

.description ul {
  list-style-type: initial;
  margin-left: 1rem;
  white-space: normal;
  margin-bottom: 1rem;
}

.description li {
  margin-top: 0.5rem;
}

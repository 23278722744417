.overlay-enter {
  opacity: 0;
}

.overlay-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.overlay-exit {
  opacity: 1;
}

.overlay-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.modal-enter {
  opacity: 0;
  transform: translateY(-30px);
}

.modal-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.modal-exit {
  opacity: 1;
  transform: translateY(0);
}

.modal-exit-active {
  opacity: 0;
  transform: translateY(-30px);
  transition: opacity 300ms, transform 300ms;
}

// Transition for usePopup hook
.popup-transition {
  transition-property: opacity, transform;
}

.popup-transition--open,
.popup-transition--close {
  transition-duration: 250ms;
}

.popup-transition--initial,
.popup-transition--close {
  opacity: 0;
  transform: translateY(-30px);
}

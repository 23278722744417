.root {
  display: flex;
  align-items: baseline;
  column-gap: 26px;
  flex-wrap: wrap;
  padding: 12px 0;
}

.item {
  position: relative;
  display: block;

  color: var(--t-accent-text);
  text-decoration: none;
  white-space: nowrap;
  transition: all .2s ease;
}
.item:hover {
  text-decoration: underline;
}

.itemActive {
  color: var(--t-contrast-text);
  font-weight: bold;
  font-size: 28px;
  pointer-events: none;
  transform: translateY(1px);
}
.itemActive:hover {
  text-decoration: none;
  color: var(--t-contrast-text);
}

.item:not(:last-child)::after {
  content: "/";
  position: absolute;
  right: -17px;
  bottom: -1px;
  color: #8a93a2;
  font-size: 1.1rem;
}

.itemText {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 1120px) {
  .itemText {
    max-width: 350px;
  }
}

@media screen and (max-width: 575px) {
  .itemActive {
    font-size: 24px;
  }
  .itemText {
    max-width: 160px;
  }
}

.root {
  border-radius: 0.25rem;
  padding: 0.2rem 0.3rem;
  font-size: 75% !important;
  font-weight: 700;
  line-height: 1;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* position modifier  */
.position--static {
  position: static;
}
.position--top-right {
  position: absolute;
  bottom: 50%;
  left: 50%;
}

.position--inline {
  white-space: normal;
  text-align: center;
  display: inline-block;
  margin: 0 0.2rem;
}

/* type modifier  */
.color--white {
  background-color: white;
  color: black;
}
.color--grey {
  background-color: #c0c0c0;
  color: black;
}
.color--danger {
  background-color: rgb(213, 103, 80);
  color: white;
}

.color--ghost {
  color: var(--t-accent-element);
  border: solid 1px var(--t-accent-element);
}

.color--ghost:hover {
  color: var(--t-accent-text-contrast-color);
  background-color: var(--t-accent-element);
  border-color: var(--t-accent-element-border-hover);
}

.color--attention {
  background-color: var(--t-attention-color);
  color: var(--t-accent-element-text);
  border: none;
}

.color--attention:hover {
  background-color: var(--t-attention-color-hover);
}

.color--simple {
  background-color: var(--t-accent-element);
  color: var(--t-accent-element-text);
  border: none;
}

.color--billing {
  background-color: var(--t-badge-billing);
  color: var(--t-accent-element-text);
  border: none;
}

.color--system {
  background-color: var(--t-badge-system);
  color: var(--t-accent-element-text);
  border: none;
}

.color--alert {
  background-color: var(--t-badge-alert);
  color: var(--t-accent-element-text);
  border: none;
}

/* rounded modifier  */
.rounded {
  padding: 0.2rem 0.6rem;
  border-radius: 10rem;
}

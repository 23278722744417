#root {
  width: 320px;
  padding: 1rem;
  --cui-toast-spacing: 0.5rem;
  background-color: var(--t-toast-bg);
  font-family: Gilroy, sans-serif;
  color: var(--t-toast-text);
  cursor: pointer;
}

#root .toastHeader {
  border: none;
  background: none;
  font-weight: bold;
  justify-content: space-between;
  padding: 0 0 0.25rem 0;
  color: var(--t-toast-text);
}

#root .toastBody {
  display: flex;
  align-items: center;
  padding: 0;
  gap: 0.5rem;
}

#root a {
  font-size: inherit !important;
  color: #00b5b5 !important;
}

#root span {
  font-size: inherit !important;
}

.customCloseButton {
  background: none;
  border: none;
  color: var(--t-toast-text);
  cursor: pointer;
  font-size: 3rem;
  line-height: 0.5rem;
  font-weight: 100;
  margin: 0;
  padding: 0;
  opacity: 0.5;
}

.customCloseButton:hover,
.customCloseButton:focus {
  opacity: 0.75;
}

.page500 {
  width: 100%;
}

.page500ColumnsContainer {
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: center;
}

.descriptionColumns {
  padding: 30px;
  display: flex;
  align-items: center;
}

.logoColumn {
  padding: 30px;
}

.logoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoSubscription {
  letter-spacing: .5rem;
  text-align: center;
  font-size: small;
  margin-left: 0.5rem;
  font-weight: 100;
}

.logo {
  display: block;
  width: 190px;
  height: 160px;
  fill: var(--t-common-text);
}

.errorExplain {
  font-weight: 800;
  font-size: large;
}

@media (max-width: 576px) {
  .page500ColumnsContainer {
    flex-direction: column;
  }

  .logoSubscription {
    max-width: 200px;
    margin: auto;
    font-size: x-small;
  }

  .errorExplain {
    text-align: center;
    max-width: 290px;
    margin: auto;
  }
}

@media (max-width: 479px) {
  .logo {
    width: 140px;
    height: 120px;
  }
}



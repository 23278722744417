.root {
  position: fixed;
  z-index: 1031;
  left: 0;
  top: 0;

  width: var(--t-sidebar-width);
  height: 100vh;
  /* It's for visibility */
  margin-left: calc(-1 * var(--t-sidebar-width));

  transition: all 0.15s;
}

.rootVisible {
  margin-left: 0;
}

.rootMinimized {
  width: var(--t-sidebar-width-minimized);
}

.rootExpanded {
  width: var(--t-sidebar-width);
}

.rootClosing {
  pointer-events: none;
}

.rootMinimized .toggler .togglerArrow {
  transform: rotate(180deg);
}

.navWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--t-sidebar-bg);
}

.brand {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  height: 56px;
  width: 100%;
  background-color: rgba(0, 0, 21, 0.2);
  color: #fff;
}

.brandLogo {
  width: 118px;
  height: 46px;

  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.title {
  color: var(--t-accent-text) !important;
  font-weight: 600 !important;
  letter-spacing: 0.05em;
  font-size: 1.125rem !important;
  height: 53px !important;
  min-height: 53px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: pre;
}

.navList {
  overflow-x: hidden;
  overflow-y: auto;
  flex-shrink: 1;
  flex-grow: 1;
}

.navLink {
  display: flex;
  align-items: center;
  column-gap: 20px;
  padding: 16px 20px;

  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  white-space: nowrap;

  transition: all 0.25s ease;
}

:local(.navLink):global(.active) {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.05);
}

.navLink:hover {
  color: var(--t-accent-text-contrast-color);
  background-color: var(--t-accent-element);
}

.navIcon {
  flex-shrink: 0;
  width: 18px !important;
  height: 18px !important;
  color: #73737e !important;
  fill: #73737e !important;
}

.navLink:hover .navIcon {
  color: currentColor;
  fill: currentColor;
}

.toggler {
  flex-shrink: 0;
  position: relative;
  width: 100%;
  height: 50px;
  padding: 0px;
  overflow: hidden;
  border: none;
  background-color: rgba(0, 0, 21, 0.2);
  transition: all 0.2s ease;
  cursor: pointer;
}

.toggler:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.toggler:hover .togglerArrow {
  fill: #fff;
}

.togglerArrow {
  position: absolute;
  right: 18px;
  top: 50%;
  margin-top: -8px;
  fill: #8a93a2;
  transition: all 0.2s ease;
}

@media screen and (max-width: 991px) {
  .backdrop {
    content: '';
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    background-color: #000015;
    opacity: 0;
    display: none;
  }

  .rootVisible .backdrop {
    opacity: 0.5;
    display: block;
  }

  .navWrapper {
    position: relative;
    z-index: 2;
  }
}
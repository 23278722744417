:root {
  // Input statuses
  --t-status-invalid: #EB593A;
  --t-status-invalid-transparent: rgba(235, 89, 58, 0.25);
  --t-status-valid: #8D90FB;
  --t-status-valid-transparent: rgba(141, 144, 251, 0.25);

  --t-sidebar-width: 16rem;
  --t-sidebar-width-minimized: 3.5rem;
  --t-sidebar-taken-place: 0;
}


// Variable overrides

$theme-colors__dt: (
  "primary": #12a3a3,
  "secondary": #22303E,
  "light": #2B2B38,
  "dark": #2c2c34,
  "black": black,
);

$theme-colors__lt: (
  "primary": #8AE9E9,
);

$theme-colors: (
  "primary": #00B5B5,
  "light-primary": #18dcdc,
  "transparent": #00000000,
  "light-gray-badge": #F0F3F5,
  "dark-green-badge": #324951,
  "dark-warning": #d75871,
  "dark-info": #7fc0f1,
  "dark-primary": #0e8080,
  "dark-content-bg": #23242D,
  "gray-button" : #7E8082,
  "dark-gray-button": #6b6d6e,
  "turquoise-button": #648D9B,
  "dark-turquoise-button": #50717C,
  "gray-status_color": #73737E
);




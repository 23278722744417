.root {
  width: 100vw;
  display: flex;
  overflow: hidden;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  margin-left: var(--t-sidebar-taken-place, 0);
  transition: all .15s;
}

.wrapperWithSidebar {
  --t-sidebar-taken-place: var(--t-sidebar-width);
}

.wrapperWithSidebarMin {
  --t-sidebar-taken-place: var(--t-sidebar-width-minimized) !important;
}

.main {
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
  min-width: 0;
  max-width: 1140px;
  margin: 0 auto;
  padding: 32px 16px 32px;
}

@media screen and (max-width: 575px) {
  .main {
    padding: 16px 16px 16px;
  }
}

@media screen and (max-width: 991px) {
  .root {
    min-height: 100vh;
  }

  .wrapper {
    margin-left: 0 !important;
    height: auto;
  }
}

@media screen and (min-width: 992px) {
  .root {
    height: 100vh;
  }
}

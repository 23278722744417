@font-face {
  font-family: "Gilroy";
  src: url("../assets/fonts/Gilroy-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../assets/fonts/Gilroy-SemiBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../assets/fonts/Gilroy-Medium.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../assets/fonts/Gilroy-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

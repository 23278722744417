.root {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.spinner {
  width: 50px;
  height: 50px;
  border-style: solid;
  border-width: 10px;
  border-color: var(--t-common-text);
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite rotate;
}

@keyframes rotate {
  from {
    rotate: 0deg;
  }

  to {
    rotate: 360deg;
  }
}

.root {
  position: relative;
  padding: 16px;

  border-radius: 4px;
  box-shadow: var(--t-card-shadow);
  word-wrap: break-word;
}
.border {
  border: 1px solid var(--t-accent-border);
}

.bgColor--primary {
  background-color: var(--t-card-bg);
}

.bgColor--plain {
  background-color: var(--t-plain-bg);
}

.noShadow {
  box-shadow: none;
}

.root {
  width: 100%;
  background-color: transparent;
}

.root td,
.root th {
  border: 1px solid var(--t-table-borders);
  padding: 0.5rem 1rem;
}

.root {
  color: var(--t-accent-text);
  text-decoration: none;
  transition: all .25s ease;
  cursor: pointer;
}

.root:hover {
  color: var(--t-accent-text-hover);
  text-decoration: underline;
}

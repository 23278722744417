.root {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;

  font-weight: 500;
  text-align: center;
  letter-spacing: 0.05em;
  vertical-align: middle;

  border: 1px solid transparent;
  cursor: pointer;
  user-select: none;
  line-height: 1.5;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.root:disabled {
  cursor: default;
  opacity: 0.65;
  pointer-events: none;
}

.root:focus {
  outline: none;
}

.rootCompressed {
  letter-spacing: normal;
}

.rootBlock {
  width: 100%;
}

:local .root :global .spinner-border {
  border-color: var(--t-accent-element-text);
  border-right-color: transparent;
}

:local .root :global .c-icon,
:local .root :global .icon {
  margin: 3px 0;
}

.spinnerWrapper {
  display: flex;
  align-items: center;
  margin-right: 18px;
}

/* theme modifier  */
.theme--dark.color--primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(54, 177, 177, 0.5);
}

.theme--dark.variant--ghost {
  background-color: #000;
}

.theme--light.color--primary {
  box-shadow: 0 1px 1px 0 rgba(60, 75, 100, .14), 0 2px 1px -1px rgba(60, 75, 100, .12), 0 1px 3px 0 rgba(60, 75, 100, .20);
}
.theme--light.color--primary:hover {
  box-shadow: 0 2px 2px 0 rgba(60, 75, 100, .14), 0 3px 1px -2px rgba(60, 75, 100, .12), 0 1px 5px 0 rgba(60, 75, 100, .20);
}

.theme--light.variant--ghost {
  background-color: transparent;
}

/* color modifier  */
.color--primary {
  border-color: var(--t-accent-element);
  background-color: var(--t-accent-element);
  color: var(--t-accent-text-contrast-color);
}
.color--primary:hover {
  background-color: var(--t-accent-element-bg-hover);
  border-color: var(--t-accent-element-border-hover);
}

.color--cancel {
  border-color: var(--t-cancel-btn-bg);
  background-color: var(--t-cancel-btn-bg);
  color: #fff;
}
.color--cancel:hover {
  background-color: var(--t-cancel-btn-bg-hover);
  border-color: var(--t-cancel-btn-bg-hover);
}

/* variant modifier  */
.variant--ghost {
  color: var(--t-accent-element);
  border-color: var(--t-accent-element);
}
.variant--ghost:hover {
  color: var(--t-accent-text-contrast-color);
  background-color: var(--t-accent-element);
  border-color: var(--t-accent-element-border-hover);
}
:local .variant--ghost :global .spinner-border {
  border-color: var(--t-accent-element);
  border-right-color: transparent;
}

.variant--link {
  width: fit-content !important;
  padding: 0;
  background-color: transparent;
  color: var(--t-accent-text);
  border: none !important;
  box-shadow: none !important;
}
.variant--link:hover {
  text-decoration: underline;
  background-color: transparent;
}
.variant--link:focus {
  box-shadow: none !important;
}

.variant--icon {
  padding: 8px;
  background-color: transparent;
  border: none !important;
}
.variant--icon:hover {
  text-decoration: underline;
  background-color: transparent;
}

.variant--inverted {
  background-color: transparent;
  border-color: transparent;
  color: var(--t-accent-element);
  box-shadow: none !important;
}
.variant--inverted:hover {
  border-color: var(--t-accent-element);
  background-color: var(--t-accent-element);
  color: var(--t-accent-text-contrast-color);
}

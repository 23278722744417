.root {
  position: relative;
  display: inline-block;
}

.root:hover .text {
  visibility: visible;
}

.rootDisabled .text {
  display: none;
}

.text {
  position: absolute;
  z-index: 1030;
  bottom: 120%;
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;

  min-width: 120px;
  padding: 5px 8px;

  font-size: 75% !important;
  color: #fff;
  text-align: center;

  background-color: black;
  border-radius: 6px;
}

.text::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}
